import { BufferGeometry, Sphere, Box3 } from 'three'
import { computeSphere, computeBox } from '@/webgl/hooks/math'

export default class CustomBufferGeometry extends BufferGeometry {
  computeBoundingSphere () {
    if (this.boundingSphere === null) {
      this.boundingSphere = new Sphere()
    }
  
    const positions = this.attributes.position.array
    const itemSize = this.attributes.position.itemSize
    if (!positions || !itemSize || positions.length < 2) {
      this.boundingSphere.radius = 0
      this.boundingSphere.center.set(0, 0, 0)
      return
    }
    computeSphere(positions, this.boundingSphere)
    if (isNaN(this.boundingSphere.radius)) {
      console.error(`
        THREE.BufferGeometry.computeBoundingSphere(): 
        Computed radius is NaN. The "position" attribute is likely to have NaN values.
      `)
    }
  }

  computeBoundingBox () {
    if (this.boundingBox === null) {
      this.boundingBox = new Box3()
    }
  
    const bbox = this.boundingBox
    const positions = this.attributes.position.array
    const itemSize = this.attributes.position.itemSize
    if (!positions || !itemSize || positions.length < 2) {
      bbox.makeEmpty()
      return
    }
    computeBox(positions, bbox)
  }
}