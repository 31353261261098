









import { Scenes } from '@/constants'
import { Component } from 'vue-property-decorator'
import BaseScene from '@/webgl/scenes/base/Base'

@Component
export default class PorchScene extends BaseScene {
  name = Scenes.PORCH

  async setup () {
    if (this.disposed) return

    // console.time(this.log('setup'))

    this.setupSprites()
    this.setupObjects()
    this.setupClouds()
    this.setupDoors()

    this.update(this.settings)

    this.resize()

    // console.timeEnd(this.log('setup'))
  }

  tick ({ time, delta }: any) {
    if (this.disposed) return

    const { objects, clouds, door } = this.$refs

    if (clouds !== undefined) {
      for (const uid in clouds) {
        const cloud = clouds[uid]
        cloud.uniforms.uTime.value += delta
      }
    }

    // objects

    const { bottle, waterCan } = objects

    const offsetP1X = Math.cos(.5 * Math.PI * time * .6)
    const offsetP1Y = Math.cos(.5 * Math.PI * time * 1)
    const offsetR1Y = Math.cos(.5 * Math.PI * time * .4)
    const offsetR1Z = Math.cos(.5 * Math.PI * time * .6)

    bottle.position.x = bottle.origin.x + offsetP1X * .0001
    bottle.position.y = bottle.origin.y + offsetP1Y * .0002
    bottle.rotation.y = offsetR1Y * Math.PI * .08 
    bottle.rotation.z = offsetR1Z * Math.PI * .0025

    const offsetP2X = Math.cos(.5 * Math.PI * time * .6)
    const offsetP2Y = Math.cos(.5 * Math.PI * time * 1)
    const offsetR2X = Math.cos(.5 * Math.PI * time * .4)
    const offsetR2Y = Math.cos(.5 * Math.PI * time * .4)
    const offsetR2Z = Math.cos(.5 * Math.PI * time * .6)

    waterCan.position.x = waterCan.origin.x + offsetP2X * .0001
    waterCan.position.y = waterCan.origin.y + offsetP2Y * .0002
    waterCan.rotation.x = offsetR2X * Math.PI * .06
    waterCan.rotation.y = offsetR2Y * Math.PI * .06
    waterCan.rotation.z = offsetR2Z * Math.PI * .01

    // door

    door.portal.uniforms.uTime.value += delta
  }
}
