import { PerspectiveCamera, Scene } from 'three'
import { Component, Inject, Prop, Vue } from 'vue-property-decorator'
import { RenderPass as ThreeRenderPass } from 'three/examples/jsm/postprocessing/RenderPass'
import Composer from '@/webgl/postprocessing/Composer'

@Component
export default class RenderPass extends Vue {
  @Prop()
  camera!: PerspectiveCamera

  @Prop()
  scene!: Scene

  @Inject()
  composer!: Composer

  pass = new ThreeRenderPass(this.scene, this.camera)

  mounted () {
    this.composer.addPass(this.pass)
  }

  destroyed () {
    this.composer.removePass(this.pass)
  }

  render () {
    return null
  }
}