









import { Scenes } from '@/constants'
import { Component } from 'vue-property-decorator'
import BaseScene from '@/webgl/scenes/base/Base'
import { MathUtils } from 'three'

@Component
export default class GardenScene extends BaseScene {
  name = Scenes.GARDEN

  async setup () {
    if (this.disposed) return

    // console.time(this.log('setup'))

    this.setupSprites()
    this.setupObjects()
    this.setupClouds()
    this.setupDoors()

    this.update(this.settings)

    this.resize()

    // console.timeEnd(this.log('setup'))
  }

  tick ({ time, delta }: any) {
    if (this.disposed) return

    const { objects, clouds, door } = this.$refs

    if (clouds !== undefined) {
      for (const uid in clouds) {
        const cloud = clouds[uid]
        cloud.uniforms.uTime.value += delta
      }
    }

    // bottle

    const { bottle } = objects

    const offsetX = Math.cos(.5 * Math.PI * time * .6)
    const offsetY = Math.cos(.5 * Math.PI * time * 1)
    const offsetZ = Math.cos(.5 * Math.PI * time * .8)

    bottle.position.x = bottle.origin.x + offsetX * .0001
    bottle.position.y = bottle.origin.y + offsetY * .0002
    bottle.rotation.z = offsetZ * Math.PI * .01

    bottle.shadow.position.x = offsetX * .0001

    const distance = bottle.position.distanceTo(bottle.floor)
    const shadowOpacity = MathUtils.mapLinear(distance, .0016, .0020, .8, .4)
    const shadowScale = MathUtils.mapLinear(distance, .0016, .0020, .8, 1.2)

    bottle.shadow.material.opacity = shadowOpacity
    bottle.shadow.scale.x = shadowScale

    // door

    door.portal.uniforms.uTime.value += delta
  }
}
