import fragmentShaderSetup from 'raw-loader!glslify-loader!./shaders/rgb-fragment-setup.glsl'
import fragmentShaderExtend from 'raw-loader!glslify-loader!./shaders/rgb-fragment-extend.glsl'
import { Component, Inject, Prop, Vue, Watch } from 'vue-property-decorator'
import { Vector2 } from 'three'

@Component
export default class RGBChunk extends Vue {
  @Prop()
  angle!: number

  @Prop()
  amount!: number

  @Prop()
  enabled!: boolean

  @Inject()
  chunks!: any

  defines = {
    USE_RGBSHIFT: '',
  }

  uniforms = {
    tDiffuse: { value: null },
    uRGBShift: { value: new Vector2() },
    uRGBShiftEnabled: { value: 0 },
  }

  @Watch('angle', { immediate: true })
  angleUpdate (angle: number) {
    this.uniforms.uRGBShift.value.x = angle
  }

  @Watch('amount', { immediate: true })
  amountUpdate (amount: number) {
    this.uniforms.uRGBShift.value.y = amount
  }

  @Watch('enabled', { immediate: true })
  enabledUpdate (enabled: boolean) {
    this.uniforms.uRGBShiftEnabled.value = enabled ? 1 : 0
  }

  mounted () {
    this.chunks['rgb'] = {
      defines: this.defines,
      uniforms: this.uniforms,
      shader: {
        '#include <rgb_setup>': fragmentShaderSetup,
        '#include <rgb_extend>': fragmentShaderExtend,
      }
    }
  }

  render () {
    return null
  }
}