import { WebGLRenderTarget, Vector2, LinearFilter, sRGBEncoding, RGBAFormat, WebGLRenderer } from 'three'
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer'

export default class Composer extends EffectComposer {
  $refs: any
  
  constructor (renderer: WebGLRenderer) {
    const size = renderer.getSize(new Vector2())
    const dpr = renderer.getPixelRatio()
    const width = size.width * dpr
    const height = size.height * dpr

    const renderTarget = new WebGLRenderTarget(width, height, {
      minFilter: LinearFilter,
			magFilter: LinearFilter,
      encoding: sRGBEncoding,
			format: RGBAFormat,
    })

    renderTarget.texture.generateMipmaps = false

    super(renderer, renderTarget)

    this.$refs = {}
	}
}