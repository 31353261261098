









import { Scenes } from '@/constants'
import { MathUtils, Mesh, Object3D, sRGBEncoding } from 'three'
import { dictionary } from '../base/config'
import { Component } from 'vue-property-decorator'
import { Water } from '@/webgl/shaders/water/Water2'
import BaseScene from '@/webgl/scenes/base/Base'

@Component
export default class BeachScene extends BaseScene {
  name = Scenes.BEACH

  async setup () {
    if (this.disposed) return

    // console.time(this.log('setup'))

    this.setupSprites()
    this.setupObjects()
    this.setupWater()
    this.setupDoors()

    this.update(this.settings)

    this.resize()

    // console.timeEnd(this.log('setup'))
  }

  setupWater () {
    const waterSetup = dictionary['water'] as any
    
    for (const waterConfig of waterSetup[this.name]) {
      
      const { uid, target, parent } = waterConfig

      const waterMesh = this.environment.getObjectByName(target) as Mesh
      const parentMesh = this.environment.getObjectByName(parent) as Object3D

      waterMesh.visible = false

      if (this.$gpu.getWT() <= 0) return

      const water = new Water(waterMesh.geometry.clone(), {
        normalMap0: this.cache['normal0'],
        normalMap1: this.cache['normal1'],
        flowMap: this.cache['flow'],
        textureHeight: this.$gpu.getWT(),
        textureWidth: this.$gpu.getWT(),
        encoding: sRGBEncoding,
      }) as any

      water.originalScale = waterMesh.scale.clone()
      water.quaternion.copy(waterMesh.quaternion)
      water.position.copy(waterMesh.position)
      water.scale.copy(waterMesh.scale)

      parentMesh.add(water)

      this.$refs[uid] = water
    }
  }

  tick ({ time }: any) {
    if (this.disposed) return

    const { objects } = this.$refs
    const { bottle, guitar } = objects

    // bottle

    const offsetP1X = Math.cos(.5 * Math.PI * time * .6)
    const offsetP1Y = Math.cos(.5 * Math.PI * time * 1)
    const offsetR1X = Math.cos(.5 * Math.PI * time * .6)
    const offsetR1Y = Math.cos(.5 * Math.PI * time * .6)
    const offsetR1Z = Math.cos(.5 * Math.PI * time * .8)

    bottle.position.x = bottle.origin.x + offsetP1X * .0001
    bottle.position.y = bottle.origin.y + offsetP1Y * .0002
    bottle.rotation.x = offsetR1X * Math.PI * .1
    bottle.rotation.y = offsetR1Y * Math.PI * .1
    bottle.rotation.z = offsetR1Z * Math.PI * .01

    bottle.shadow.position.x = bottle.shadow.origin.x + offsetP1X * .0001

    const bottleDistance = bottle.position.distanceTo(bottle.floor)
    //const bottleShadowOpacity = MathUtils.mapLinear(bottleDistance, .0024, .0028, .7, .3)
    const bottleShadowScale = MathUtils.mapLinear(bottleDistance, .0024, .0028, 1, 2)
    
    //bottle.shadow.material.opacity = bottleShadowOpacity
    bottle.shadow.scale.x = bottleShadowScale
    
    // guitar

    const offsetP2X = Math.cos(Math.PI + .5 * Math.PI * time * .6)
    const offsetP2Y = Math.cos(Math.PI + .5 * Math.PI * time * 1)
    const offsetR2X = Math.cos(Math.PI + .5 * Math.PI * time * .6)
    const offsetR2Y = Math.cos(Math.PI + .5 * Math.PI * time * .6)
    const offsetR2Z = Math.cos(Math.PI + .5 * Math.PI * time * .8)

    guitar.position.x = guitar.origin.x + offsetP2X * .0001
    guitar.position.y = guitar.origin.y + offsetP2Y * .0002
    guitar.rotation.x = offsetR2X * Math.PI * .1
    guitar.rotation.y = offsetR2Y * Math.PI * .1
    guitar.rotation.z = offsetR2Z * Math.PI * .01

    guitar.shadow.position.x = guitar.shadow.origin.x + offsetP2X * .0001

    const guitarDistance = guitar.position.distanceTo(guitar.floor)
    const guitarShadowOpacity = MathUtils.mapLinear(guitarDistance, .0032, .0036, .7, .6)
    const guitarShadowScale = MathUtils.mapLinear(guitarDistance, .0032, .0036, .8, 1.2)
    
    guitar.shadow.material.opacity = guitarShadowOpacity
    guitar.shadow.scale.x = guitarShadowScale

    // door

    //door.portal.uniforms.uTime.value += delta
  }
}
