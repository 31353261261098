import { Vector2 } from 'three'
import { UnrealBloomPass } from './pass'
import { Component, Inject, Prop, Vue, Watch } from 'vue-property-decorator'
import Composer from '@/webgl/postprocessing/Composer'

@Component
export default class Bloom extends Vue {
  @Prop()
  threshold!: number

  @Prop()
  strength!: number

  @Prop()
  radius!: number

  @Prop()
  mask!: number

  @Prop()
  enabled!: boolean

  @Inject()
  composer!: Composer

  pass = new UnrealBloomPass(new Vector2(512, 512), 1.5, .4, .8)

  @Watch('threshold', { immediate: true })
  thresholdUpdate (threshold: number) {
    this.pass.threshold = threshold
  }

  @Watch('strength', { immediate: true })
  strengthUpdate (strength: number) {
    this.pass.strength = strength
  }

  @Watch('radius', { immediate: true })
  radiusUpdate (radius: number) {
    this.pass.radius = radius
  }

  @Watch('mask', { immediate: true })
  maskUpdate (mask: number) {
    this.pass.mask = mask
  }

  @Watch('enabled', { immediate: true })
  enabledUpdate (enabled: boolean) {
    this.pass.enabled = enabled
  }
  
  mounted () {
    this.composer.$refs.bloom = this.pass
    this.composer.addPass(this.pass)
  }
  
  destroyed () {
    this.composer.removePass(this.pass)
  }

  render () {
    return null
  }
}