import { VNode } from 'vue'
import { ChunkPass } from './pass'
import { Component, Inject, Provide, Vue } from 'vue-property-decorator'
import { RawShaderMaterial, WebGLRenderer } from 'three'
import fragmentShader from 'raw-loader!glslify-loader!./pass/shaders/fragment.glsl'
import vertexShader from 'raw-loader!glslify-loader!./pass/shaders/vertex.glsl'
import Composer from '@/webgl/postprocessing/Composer'

//import { FluidShader } from '@/webgl/shaders/fluid'
//import { Events } from '@/constants'


@Component
export default class Chunk extends Vue {
  @Inject()
  renderer!: WebGLRenderer

  @Inject()
  composer!: Composer

  //@Provide()
  //fluid = new FluidShader(this.renderer)

  @Provide()
  chunks = [] as any

  pass!: ChunkPass

  defines = {} as any
  
  uniforms = {} as any
  
  fragmentShader = fragmentShader

  /* tick () {
    this.uniforms.tFluid.value = this.fluid.density.read.texture
    
    this.fluid.render()
  } */

  compile () {
    for (const key in this.chunks) {
      const chunk = this.chunks[key]
      
      this.uniforms = { 
        ...this.uniforms, 
        ...chunk.uniforms 
      }
      
      this.defines = { 
        ...this.defines, 
        ...chunk.defines 
      }

      for (const include in chunk.shader) {
        const shaderChunk = chunk.shader[include]
        this.fragmentShader = this.fragmentShader.replace(include, shaderChunk)
      }
    }

    return {
      defines: this.defines,
      uniforms: this.uniforms,
      fragmentShader: this.fragmentShader,
      vertexShader,
    } 
  }

  mounted () {
    this.pass = new ChunkPass(new RawShaderMaterial(this.compile()))
    this.composer.addPass(this.pass)
    //this.composer.$refs.blur = this.chunks['blur']
    //this.composer.$refs.mask = this.chunks['mask']
    //this.composer.$refs.fluid = this.fluid
    //this.$bus.$on(Events.GL.RENDER, this.tick)
  }

  destroyed () {
    //this.$bus.$off(Events.GL.RENDER, this.tick)
    this.composer.removePass(this.pass)
    delete this.composer.$refs.chunkPass
  }

  render (h: (tag: string, config: any, slots: VNode[] | undefined) => VNode) {
    return h('span', { class: 'gl-postprocessing-pass' }, this.$slots.default)
  }
}