import { gsap } from 'gsap'
import { Getter } from 'vuex-class'
import { Events } from '@/constants'
import { dictionary } from './config'
import { HotspotState, SettingState } from '@/store/types'
import { fetchSceneAssets } from '@/services/assets'
import { Component, Inject, Prop, Provide, Vue, Watch } from 'vue-property-decorator'
import { Color, Group, Mesh, MeshStandardMaterial, PerspectiveCamera, Scene, Vector2, Vector3, WebGLRenderer } from 'three'
import spriteFragmentShaderSetup from 'raw-loader!glslify-loader!../shaders/sprite/fragment-setup.glsl'
import spriteFragmentShaderExtend from 'raw-loader!glslify-loader!../shaders/sprite/fragment-extend.glsl'
import cloudFragmentShaderSetup from 'raw-loader!glslify-loader!../shaders/clouds/fragment-setup.glsl'
import cloudFragmentShaderExtend from 'raw-loader!glslify-loader!../shaders/clouds/fragment-extend.glsl'
import doorFragmentShaderSetup from 'raw-loader!glslify-loader!../shaders/door/fragment-setup.glsl'
import doorFragmentShaderExtend from 'raw-loader!glslify-loader!../shaders/door/fragment-extend.glsl'
import Composer from '@/webgl/postprocessing/Composer'
import * as cache from '@/services/cache'

@Component({
  components: {
    Helpers: () => import(/* webpackChunkName: "debug" */ '@/webgl/helpers/Group'),
  }
})
export default class BaseScene extends Vue {
  @Getter('settings')
  settings!: SettingState

  @Prop()
  hotspots!: HotspotState[]

  @Prop()
  helpers!: boolean

  @Prop()
  bounding!: any

  @Prop()
  muted!: boolean

  @Inject()
  renderer!: WebGLRenderer

  @Inject()
  camera!: PerspectiveCamera

  @Inject()
  composer!: Composer

  @Inject()
  scene!: Scene

  @Provide()
  root = new Group()

  name = 'scene'

  disposed = false

  compiled = false

  environment!: Group

  cache = undefined as any

  sharedCache = undefined as any

  $refs!: { [key: string]: any }

  @Inject()
  cameraOffset!: Vector2

  @Inject()
  cameraDefaultDepth!: number

  @Inject()
  getViewportFromFov!: (depth: number) => Vector2

  @Inject('play')
  playSound!: (name: string | string[]) => void

  @Inject('stop')
  stopSound!: (name: string) => void

  @Inject('setVolume')
  setVolume!: (vol: number) => void

  log (fn: string) {
    return `${this.$fn.capitalize(this.name)}Scene:${fn}`
  }

  async fetch () {
    if (this.disposed) return

    if (this.cache !== undefined) return

    // console.time(this.log('fetch'))
    await fetchSceneAssets(this.name)


    // console.timeEnd(this.log('fetch'))
  }

  async parse () {
    if (this.disposed) return

    // console.time(this.log('parse'))

    this.cache = cache.get(`${this.name}-cache`)
    this.sharedCache = cache.get(`shared-cache`)

    this.environment = this.cache['gltf-environment']
    this.environment.traverse(object => {
      if (object instanceof Mesh) {
        // console.log({name: object.name})
        object.material.depthWrite = true
        object.material.alphaTest = .15

        for (const name of dictionary['envMap']) {
          if (name === object.material.name && !object.material.envMap) {
            object.material = object.material.clone()
            object.material.envMap = this.sharedCache['env-map']
          }
        }
      }
    })

    // console.timeEnd(this.log('parse'))
  }

  async setup () {
    if (this.disposed) return
  }

  setupSprites () {
    const sprites = dictionary['sprites'] as any
    const spriteConfigs = sprites[this.name]

    this.$refs.sprites = spriteConfigs.length ? {} : undefined

    for (const spriteConfig of spriteConfigs) {

      const { uid, frames, target, uniforms, shared, loop: { duration, repeatDelay, timeScale } } = spriteConfig

      const spriteMesh = this.environment.getObjectByName(target) as Mesh
      const spriteMaterial = spriteMesh.material as MeshStandardMaterial
      spriteMesh.material = spriteMaterial.clone()

      const spriteObject = {
        material: spriteMesh.material as MeshStandardMaterial,
        uniforms: { ...uniforms },
        timeline: {},
      }

      spriteObject.timeline = gsap
          .timeline({ repeat: -1, repeatDelay, defaults: { duration, ease: 'linear' } })
            .fromTo(spriteObject.uniforms.uTime, { value: 0 }, { value: 1 })
            .timeScale(timeScale)

      spriteObject.material.map = shared ? this.sharedCache[frames] : this.cache[frames]
      spriteObject.material.onBeforeCompile = (shader: any) => {
        shader.uniforms = {
          ...shader.uniforms,
          ...spriteObject.uniforms,
        }

        shader.fragmentShader = shader.fragmentShader
          .replace('#include <common>', spriteFragmentShaderSetup)
          .replace('#include <map_fragment>', spriteFragmentShaderExtend)
      }

      this.$refs.sprites[uid] = spriteObject
    }
  }

  setupObjects () {
    const objects = dictionary['objects'] as any
    const objectConfigs = objects[this.name]

    this.$refs.objects = objectConfigs.length ? {} : undefined

    for (const objectConfig of objectConfigs) {

      const { uid, target, shadow, resize } = objectConfig

      const object = this.environment.getObjectByName(target) as any
      object.origin = new Vector3().copy(object.position)
      object.floor = new Vector3().copy(object.origin).setY(0)
      object.originalScale = object.scale.clone()
      object.resizable = resize

      if (shadow) {
        object.shadow = this.environment.getObjectByName(shadow) as any
        const position = object.shadow.userData.position || object.shadow.position
        object.shadow.origin = new Vector3().copy(position)
        object.shadow.material = object.shadow.material.clone()
        object.shadow.userData.position = position.clone()
      }

      this.$refs.objects[uid] = object
    }
  }

  setupClouds () {
    const clouds = dictionary['clouds'] as any
    const cloudConfigs = clouds[this.name]

    this.$refs.clouds = cloudConfigs.length ? {} : undefined

    for (const cloudConfig of cloudConfigs) {

      const { uid, target, texture, uniforms } = cloudConfig

      const cloudMesh = this.environment.getObjectByName(target) as any
      const cloudMaterial = cloudMesh.material as MeshStandardMaterial
      cloudMesh.material = cloudMaterial.clone()
      cloudMesh.material.color = new Color(0xffffff)
      cloudMesh.originalScale = cloudMesh.scale.clone()

      const cloudObject = {
        instance: cloudMesh,
        material: cloudMesh.material,
        uniforms: { ...uniforms },
      }

      cloudObject.material.map = this.sharedCache[texture]
      cloudObject.material.onBeforeCompile = (shader: any) => {
        shader.defines = {
          ...shader.defines,
          USE_UV: '',
        }

        shader.uniforms = {
          ...shader.uniforms,
          ...cloudObject.uniforms,
        }

        shader.fragmentShader = shader.fragmentShader
          .replace('#include <common>', cloudFragmentShaderSetup)
          .replace('#include <map_fragment>', cloudFragmentShaderExtend)
      }

      this.$refs.clouds[uid] = cloudObject
    }
  }

  setupDoors () {
    const doors = dictionary['doors'] as any
    const doorConfigs = doors[this.name]

    this.$refs.door = doorConfigs.length ? {} : undefined

    for (const doorConfig of doorConfigs) {

      const { uid, target, uniforms } = doorConfig

      const doorMesh = this.environment.getObjectByName(target) as any
      const doorMaterial = doorMesh.material as MeshStandardMaterial
      doorMesh.material = doorMaterial.clone()

      if (uid === 'portal') {
        const portalObject = {
          instance: doorMesh,
          material: doorMesh.material,
          uniforms: { ...uniforms },
        }

        portalObject.material.onBeforeCompile = (shader: any) => {
          shader.defines = {
            ...shader.defines,
            USE_UV: ''
          },

          shader.uniforms = {
            ...shader.uniforms,
            ...portalObject.uniforms,
          }

          shader.fragmentShader = shader.fragmentShader
            .replace('#include <common>', doorFragmentShaderSetup)
            .replace('#include <map_fragment>', doorFragmentShaderExtend)
        }

        this.$refs.door[uid] = portalObject

      } else {

        doorMesh.rotation.y = 0

        this.$refs.door[uid] = doorMesh
      }
    }
  }

  async compile () {
    if (this.disposed) return

    // console.time(this.log('compile'))

    for (const key in this.cache) {
      const asset = this.cache[key]
      if (asset.isTexture) {
        this.renderer.initTexture(asset)
      }
    }

    for (const key in this.sharedCache) {
      const asset = this.sharedCache[key]
      if (asset.isTexture) {
        this.renderer.initTexture(asset)
      }
    }

    this.$bus.$emit(Events.GL.COMPILE)

    this.compiled = true

    // console.timeEnd(this.log('compile'))
  }

  async listen () {
    this.$bus.$on(Events.GL.RENDER, this.tick)
    this.$bus.$on(Events.GUI.CHANGE, this.update)
  }

  async reveal () {
    if (this.disposed) return

    // console.time(this.log('reveal'))

    this.root.add(this.environment)

    this.$bus.$emit(Events.GL.REVEAL)

    return new Promise<void>(resolve => {

      const { glow } = this.$gl
      const { bloom } = this.composer.$refs
      const ratio = this.bounding.screen.x / this.bounding.screen.y

      gsap.timeline({
            onComplete: () => { this.$bus.$emit(Events.GL.ACTIVE); resolve() },
            onStart: () => {
              this.setVolume(~~!this.muted)
              this.playSound('sound-transition')
              this.playSound(`sound-${this.name}-environment`)
            }
          })
          .add(
            gsap.timeline()
                .fromTo(glow.uniforms.uReveal, { value: 0 }, { value: 1, duration: 3, ease: 'power2.inOut' }, '<')
                .fromTo(glow.uniforms.uRatio.value, { x: ratio, y: 1 }, { x: .2, y: 2, duration: 3, ease: 'power2.inOut' }, '<')
                .fromTo(bloom || { strength: 0 }, { strength: 3 }, { strength: .2, duration: 3, ease: 'power2.inOut' }, '<')
          , '<')
          .add(
            gsap.timeline()
                .fromTo(this.camera.position, { z: -.015 }, { precision: { z: this.cameraDefaultDepth }, duration: 4, ease: 'power2.inOut' }, '<')
          , '<')

      // console.timeEnd(this.log('reveal'))

    })
  }

  async leave () {
    return new Promise<void>(resolve => {

      const { door } = this.$refs
      const { mobile } = this.$device
      const { bloom } = this.composer.$refs
      const { glow, flowers } = this.$gl
      const hotspot = this.hotspots.find(({ uid }) => 'next-scene' === uid) as HotspotState

      gsap.timeline({
            onComplete: resolve,
            onStart: () => this.stopSound(`sound-${this.name}-environment`),
          })
          .add(
            gsap.timeline()
                .to(this.cameraOffset, { precision: { x: hotspot.position.x }, duration: 1, ease: 'power2.out' }, '<')
                .to(this.camera.position, { precision: { z: -.015 }, duration: 4, ease: 'power2.inOut' }, '<+.2')
          , '<')
          .add(
            gsap.timeline()
                .to(door ? door.left.rotation : { y: 0 }, { y: -Math.PI / 2, duration: 2, ease: 'power2.inOut' }, '<')
                .to(door ? door.right.rotation : { y: 0 }, { y: Math.PI / 2, duration: 2, ease: 'power2.inOut' }, '<+.12')
          , '<')
          .add(
            gsap.timeline()
                .fromTo(flowers.uniforms.uOffset.value, { x: 0, y: .002 }, { precision: { x: .003, y: 0 }, duration: 4, ease: 'power2.inOut' }, '<')
                .fromTo(flowers.uniforms.uOffset.value, { z: -.04 }, { precision: { z: mobile ? -.062 : -.058 }, duration: 4, ease: 'power2.inOut' }, '<')
                .fromTo(flowers.uniforms.uReveal, { value: 0 }, { value: 5.6, duration: 4, ease: 'linear' }, '<')
          , '<+.2')
          .add(
            gsap.timeline()
                .to(bloom || { strength: 0 }, { strength: 3, duration: 5, ease: 'power2.inOut' }, '<')
                .fromTo(glow.uniforms.uRatio.value, { x: .2, y: 2 }, { x: 1, y: 1, duration: 3, ease: 'power2.out' }, '<')
                .fromTo(glow.uniforms.uReveal, { value: 1 }, { value: 0, duration: 3, ease: 'power2.inOut' }, '<')
          , '<+.8')
    })
  }

  // eslint-disable-next-line
  tick (_: any) {
    if (this.disposed) return
  }

  update ({ clouds, water, door }: any) {
    if (this.disposed) return

    if (this.$refs.clouds !== undefined) {
      for (const uid in this.$refs.clouds) {
        const cloud = this.$refs.clouds[uid]
        cloud.uniforms.uNoiseParams.value.set(clouds.noise.x.value, clouds.noise.y.value, clouds.noise.z.value, clouds.noise.w.value)
        cloud.uniforms.uOffsetParams.value.set(clouds.offset.x.value, clouds.offset.y.value)
      }
    }

    if (this.$refs.door !== undefined) {
      const { left, right, portal } = this.$refs.door
      left.rotation.y = -door.reveal.value
      right.rotation.y = door.reveal.value
      if (portal) {
        portal.uniforms.uNoiseParams.value.set(door.noise.x.value, door.noise.y.value, door.noise.z.value, door.noise.w.value)
      }
    }

    if (this.$refs.water !== undefined) {
      this.$refs.water.material.uniforms.reflectivity.value = water.reflectivity.value
      this.$refs.water.material.uniforms.config.value.x = water.offset.x.value
      this.$refs.water.material.uniforms.config.value.w = water.offset.y.value
    }
  }

  @Watch('bounding.screen', { deep: true})
  resize () {
    if (this.$refs.objects !== undefined) {
      for (const uid in this.$refs.objects) {
        const object = this.$refs.objects[uid]
        if (object.resizable) {
          object.scale.setX(this.getFullScreenScale(object) + .5)
        }
      }
    }

    if (this.$refs.clouds !== undefined) {
      for (const uid in this.$refs.clouds) {
        const { instance: cloud } = this.$refs.clouds[uid]
        cloud.scale.setX(this.getFullScreenScale(cloud) + .5)
        //cloud.scale.setScalar(this.getFullScreenScale(cloud) + .5).setZ(cloud.originalScale.z)
      }
    }

    if (this.$refs.water !== undefined) {
      const { water } = this.$refs
      water.scale.setX(this.getFullScreenScale(water) + .5)
    }
  }

  getFullScreenScale (mesh: any) {
    const { position } = mesh.geometry.attributes
    const depth = mesh.position.z + position.array[2]
    const { width } = this.getViewportFromFov(depth)
    const originalScale = mesh.originalScale.x
    const scale = (width / 2) / Math.abs(position.array[0])
    return Math.max(originalScale, scale)
  }

  async unlisten () {
    this.$bus.$off(Events.GL.RENDER, this.tick)
    this.$bus.$off(Events.GUI.CHANGE, this.update)
  }

  async dispose () {
    // console.time(this.log('dispose'))

    this.scene.remove(this.root)
    this.root.remove(this.environment)

    if (this.$refs.sprites !== undefined) {
      for (const key in this.$refs.sprites) {
        const sprite = this.$refs.sprites[key]
        if (sprite.timeline !== undefined)
          sprite.timeline.kill()
        if (sprite.material !== undefined)
          this.disposeMaterial(sprite.material)
        delete this.$refs.sprites[key]
      }
    }

    if (this.$refs.clouds !== undefined) {
      for (const key in this.$refs.clouds) {
        const cloud = this.$refs.clouds[key]
        if (cloud.material !== undefined)
          this.disposeMaterial(cloud.material)
        delete this.$refs.clouds[key]
      }
    }

    if (this.$refs.door !== undefined) {
      for (const key in this.$refs.door) {
        const door = this.$refs.door[key]
        if (door.material !== undefined)
          this.disposeMaterial(door.material)
        delete this.$refs.door[key]
      }
    }

    if (this.$refs.water !== undefined) {
      this.$refs.water.parent.remove(this.$refs.water)
      this.$refs.water.geometry.dispose()
      this.$refs.water.material.dispose()
      delete this.$refs.water
    }

    // console.timeEnd(this.log('dispose'))
  }

  disposeMaterial (material: any) {
    material.dispose()

    for (const key of Object.keys(material)) {
      const value = material[key]
      if (value && typeof value === 'object' && 'minFilter' in value) {
        value.dispose()
      }
    }
  }

  async mount (done: () => void, reveal = true): Promise<void> {
    return new Promise<void>(resolve => {
      (async () => {
        await this.fetch()
        await this.parse()
        await this.setup()
        await this.compile()
        await this.listen()
        resolve()
        if (reveal)
          await this.reveal()
        done()
      })()
    })

    /* return new Promise<void>(async resolve => {
      await this.fetch()
      await this.parse()
      await this.setup()
      await this.compile()
      await this.listen()
      if (reveal)
        this.reveal()
      resolve()
    }) */
  }

  unmount (done: () => void): Promise<void> {
    this.disposed = true
    return new Promise<void>(resolve => {
      (async () => {
        await this.unlisten()
        await this.leave()
        await this.dispose()
        resolve()
        done()
      })()
    })

    /* this.disposed = true
    return new Promise<void>(async resolve => {
      await this.unlisten()
      await this.leave()
      await this.dispose()
      resolve()
    }) */
  }

  mounted () {    // console.log(this.log('mounted'))
    this.cache = cache.get(`${this.name}-cache`)
    this.scene.add(this.root)
  }

  render () {
    return null
  }
}
