import { Vector2, Vector4 } from 'three'

export const dictionary = {
  envMap: [
    'pasted__material_bottiglietta',
    'material_bottiglietta',
    'material_porta',
  ],

  sprites: {
    garden: [
      {
        frames: 'sprite-pear-blossom-accord',
        target: 'model_pera',
        uid: 'pear',
        uniforms: {
          uTime: { value: 0 },
          uFrames: { value: 24 },
          uSteps: { value: new Vector2(8, 4) },
          uOffset: { value: new Vector2(0, 0) },
        },
        loop: {
          repeatDelay: 0,
          timeScale: 1.2,
          duration: 2,
        },
      },
      {
        frames: 'sprite-dog',
        target: 'model_cane',
        uid: 'dog',
        uniforms: {
          uTime: { value: 0 },
          uFrames: { value: 36 },
          uSteps: { value: new Vector2(6, 6) },
          uOffset: { value: new Vector2(0, 0) },
        },
        loop: {
          repeatDelay: 3,
          timeScale: 1,
          duration: 3,
        },
      },
      {
        frames: 'sprite-cat-dog',
        target: 'model_gatto_cane',
        uid: 'cat-dog',
        uniforms: {
          uTime: { value: 0 },
          uFrames: { value: 5 },
          uSteps: { value: new Vector2(5, 1) },
          uOffset: { value: new Vector2(0, 0) },
        },
        loop: {
          repeatDelay: 3,
          timeScale: 1,
          duration: .3,
        },
      },
      {
        frames: 'sprite-cats',
        target: 'model_gatti_giardino',
        uid: 'cats',
        shared: true,
        uniforms: {
          uTime: { value: 0 },
          uFrames: { value: 28 },
          uSteps: { value: new Vector2(7, 8) },
          uOffset: { value: new Vector2(0, 0) },
        },
        loop: {
          repeatDelay: 0,
          timeScale: 1,
          duration: 2,
        },
      },
      {
        frames: 'sprite-jasmine-white-gardenia',
        target: 'model_gelsomino',
        uid: 'jasmine',
        uniforms: {
          uTime: { value: 0 },
          uFrames: { value: 24 },
          uSteps: { value: new Vector2(8, 6) },
          uOffset: { value: new Vector2(0, 0) },
        },
        loop: {
          repeatDelay: 0,
          timeScale: 1.2,
          duration: 2,
        },
      },
      {
        frames: 'sprite-cat-ear',
        target: 'model_gatto_orecchio',
        uid: 'cat-ear',
        uniforms: {
          uTime: { value: 0 },
          uFrames: { value: 4 },
          uSteps: { value: new Vector2(4, 1) },
          uOffset: { value: new Vector2(0, 0) },
        },
        loop: {
          repeatDelay: 3,
          timeScale: 1,
          duration: .3,
        },
      },
      {
        frames: 'sprite-cat-tail-cat-porch',
        target: 'model_gatto_coda',
        uid: 'cat-tail',
        shared: true,
        uniforms: {
          uTime: { value: 0 },
          uFrames: { value: 28 },
          uSteps: { value: new Vector2(7, 8) },
          uOffset: { value: new Vector2(0, 0) },
        },
        loop: {
          repeatDelay: 0,
          timeScale: 1,
          duration: 2,
        },
      },
      {
        frames: 'sprite-jasmine-white-gardenia',
        target: 'model_gardenia',
        uid: 'gardenia',
        uniforms: {
          uTime: { value: 0 },
          uFrames: { value: 24 },
          uSteps: { value: new Vector2(8, 6) },
          uOffset: { value: new Vector2(0, 3) },
        },
        loop: {
          repeatDelay: 0,
          timeScale: 1.2,
          duration: 2,
        },
      }
    ],

    porch: [
      {
        frames: 'sprite-red-cat',
        target: 'model_sprite_gatto_rosso',
        uid: 'red-cat',
        uniforms: {
          uTime: { value: 0 },
          uFrames: { value: 36 },
          uSteps: { value: new Vector2(6, 6) },
          uOffset: { value: new Vector2(0, 0) },
        },
        loop: {
          repeatDelay: 0,
          timeScale: 1,
          duration: 3,
        },
      },
      {
        frames: 'sprite-cats',
        target: 'model_sprite_gatto_bianco',
        uid: 'white-cat',
        shared: true,
        uniforms: {
          uTime: { value: 0 },
          uFrames: { value: 28 },
          uSteps: { value: new Vector2(7, 8) },
          uOffset: { value: new Vector2(0, 4) },
        },
        loop: {
          repeatDelay: 0,
          timeScale: 1,
          duration: 2,
        },
      },
      {
        frames: 'sprite-cat-tail-cat-porch',
        target: 'model_sprite_gatto_portico',
        uid: 'porch-cat',
        shared: true,
        uniforms: {
          uTime: { value: 0 },
          uFrames: { value: 28 },
          uSteps: { value: new Vector2(7, 8) },
          uOffset: { value: new Vector2(0, 4) },
        },
        loop: {
          repeatDelay: 0,
          timeScale: 1,
          duration: 2,
        },
      },
      {
        frames: 'sprite-stairs-cat',
        target: 'model_sprite_gatto_scale',
        uid: 'stairs-cat',
        uniforms: {
          uTime: { value: 0 },
          uFrames: { value: 48 },
          uSteps: { value: new Vector2(8, 6) },
          uOffset: { value: new Vector2(0, 0) },
        },
        loop: {
          repeatDelay: 2,
          timeScale: .8,
          duration: 2,
        },
      }
    ],

    beach: [
      {
        frames: 'dog-crab-sprite',
        target: 'model_cane_paguro',
        uid: 'dog',
        uniforms: {
          uTime: { value: 0 },
          uFrames: { value: 60 },
          uSteps: { value: new Vector2(6, 12) },
          uOffset: { value: new Vector2(0, 0) },
        },
        loop: {
          repeatDelay: 0,
          timeScale: 1,
          duration: 5,
        },
      },
      {
        frames: 'white-gardenia-sprite',
        target: 'model_fiore_gigante',
        uid: 'gardenia',
        uniforms: {
          uTime: { value: 0 },
          uFrames: { value: 36 },
          uSteps: { value: new Vector2(6, 6) },
          uOffset: { value: new Vector2(0, 0) },
        },
        loop: {
          repeatDelay: 0,
          timeScale: 1,
          duration: 3,
        },
      },
      {
        frames: 'shell-sprite',
        target: 'model_conchiglia',
        uid: 'shell',
        uniforms: {
          uTime: { value: 0 },
          uFrames: { value: 36 },
          uSteps: { value: new Vector2(6, 6) },
          uOffset: { value: new Vector2(0, 0) },
        },
        loop: {
          repeatDelay: 0,
          timeScale: 1,
          duration: 2,
        },
      },
      {
        frames: 'crab-sprite',
        target: 'model_granchio',
        uid: 'crab',
        uniforms: {
          uTime: { value: 0 },
          uFrames: { value: 30 },
          uSteps: { value: new Vector2(6, 6) },
          uOffset: { value: new Vector2(0, 0) },
        },
        loop: {
          repeatDelay: 2,
          timeScale: 1,
          duration: 2,
        },
      },
      {
        frames: 'crab-sprite',
        target: 'model_granchio1',
        uid: 'crab',
        uniforms: {
          uTime: { value: 0 },
          uFrames: { value: 30 },
          uSteps: { value: new Vector2(6, 6) },
          uOffset: { value: new Vector2(0, 0) },
        },
        loop: {
          repeatDelay: 2,
          timeScale: 1,
          duration: 2,
        },
      }
    ],
  },

  objects: {
    garden: [
      {
        uid: 'bottle',
        target: 'Model_Bottiglietta',
        shadow: 'Ombra4',
      },
      {
        uid: 'sky',
        target: 'Model_Cielo',
        resize: true
      },
      {
        uid: 'sea',
        target: 'pPlane39',
        resize: true
      },
      {
        uid: 'ground1',
        target: 'model_terreno',
        resize: true
      },
      {
        uid: 'ground2',
        target: 'model_terreno1',
        resize: true
      }
    ],

    porch: [
      {
        uid: 'bottle',
        target: 'modello_3d_Bottiglietta',
      },
      {
        uid: 'waterCan',
        target: 'modello_3d_annaffiatoio'
      },
      {
        uid: 'sky',
        target: 'Model_Cielo',
        resize: true
      },
      {
        uid: 'ground1',
        target: 'model_terreno_grass',
        resize: true
      },
      {
        uid: 'ground2',
        target: 'model_terreno_trasparente',
        resize: true
      }
    ],

    beach: [
      {
        uid: 'bottle',
        target: 'Model_Bottiglietta',
        shadow: 'pPlane62'
      },
      {
        uid: 'guitar',
        target: 'Model_3d_chitarra',
        shadow: 'pPlane64'
      },
      {
        uid: 'sky',
        target: 'Model_Cielo',
        resize: true
      },
      {
        uid: 'sea',
        target: 'pPlane39',
        resize: true
      },
      {
        uid: 'ground',
        target: 'Spiaggia_Nuova',
        resize: true
      }
    ]
  },

  clouds: {
    garden: [
      {
        texture: 'map-cloud-1',
        target: 'model_nuvola_2',
        uid: 'left',
        uniforms: { 
          uNoiseParams: { value: new Vector4() }, 
          uOffsetParams: { value: new Vector2() },
          uTime: { value: 0 },
        }
      },
      {
        texture: 'map-cloud-2',
        target: 'model_nuvola_1',
        uid: 'right',
        uniforms: { 
          uNoiseParams: { value: new Vector4() }, 
          uOffsetParams: { value: new Vector2() },
          uTime: { value: 0 },
        }
      }
    ],

    porch: [
      {
        texture: 'map-cloud-1',
        target: 'pasted__model_nuvola_2',
        uid: 'left',
        uniforms: { 
          uNoiseParams: { value: new Vector4() }, 
          uOffsetParams: { value: new Vector2() },
          uTime: { value: 0 },
        }
      },
      {
        texture: 'map-cloud-2',
        target: 'pasted__model_nuvola_1',
        uid: 'right',
        uniforms: { 
          uNoiseParams: { value: new Vector4() }, 
          uOffsetParams: { value: new Vector2() },
          uTime: { value: 0 },
        }
      }
    ]
  },

  water: {
    beach: [
      {
        uid: 'water',
        parent: 'Assets_01',
        target: 'MARE_nuovo_livello',
      }
    ]
  },

  doors: {
    garden: [
      {
        uid: 'left',
        target: 'porta_1_anta_sx'
      },
      {
        uid: 'right',
        target: 'porta_1_anta_dx'
      },
      {
        uid: 'portal',
        target: 'porta_pannello_retro_apertura',
        uniforms: { 
          uNoiseParams: { value: new Vector4() }, 
          uTime: { value: 0 },
        }
      }
    ],

    porch: [
      {
        uid: 'left',
        target: 'porta_1_anta_sx'
      },
      {
        uid: 'right',
        target: 'porta_1_anta_dx'
      },
      {
        uid: 'portal',
        target: 'porta_pannello_retro_apertura',
        uniforms: { 
          uNoiseParams: { value: new Vector4() }, 
          uTime: { value: 0 },
        }
      }
    ],

    beach: [
      {
        uid: 'left',
        target: 'porta_1_anta_sx'
      },
      {
        uid: 'right',
        target: 'porta_1_anta_dx'
      },
      /* {
        uid: 'portal',
        target: 'porta_pannello_retro_apertura',
        uniforms: { 
          uNoiseParams: { value: new Vector4() }, 
          uTime: { value: 0 },
        }
      } */
    ]
  }
}