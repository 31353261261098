import { Getter } from 'vuex-class'
import { Events } from '@/constants'
import { Component, Vue, Provide, Prop } from 'vue-property-decorator'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
import { MathUtils, PerspectiveCamera, Scene, sRGBEncoding, Vector2, Vector3, WebGLRenderer, AudioListener } from 'three'
import Composer from '@/webgl/postprocessing/Composer'

@Component
export default class ThreeProvider extends Vue {
  @Getter('dpr')
  dpr!: number

  @Provide()
  renderer = new WebGLRenderer({
    powerPreference: "high-performance",
    antialias: false,
    stencil: false,
    alpha: false,
  })

  @Provide()
  camera = new PerspectiveCamera(33, 1, .001, .2)

  @Provide()
  devCamera = new PerspectiveCamera(55, 1, .001, 10)

  @Provide()
  orbit = new OrbitControls(this.devCamera, this.renderer.domElement)

  @Provide()
  composer = new Composer(this.renderer)

  @Provide()
  scene = new Scene()

  @Provide()
  cameraOffset = new Vector2()

  @Provide()
  cameraDefaultDepth = -.0025

  @Provide()
  cameraWorldOffset = new Vector3()

  cameraLerpPosition = new Vector3()

  @Prop()
  audioListener!: AudioListener

  pointerOffset = new Vector2()
  
  pointer = new Vector2()
  
  viewport = new Vector2()
  
  snapTarget = null as any

  bounding = {
    screen: new Vector2(), 
    scene: new Vector2(),
    depth: 0,
    size: 0,
  }
  
  pixelRatio = 1

  debug = false

  @Provide()
  debugMode (debug: boolean) {
    this.orbit.enabled = debug
    this.debug = debug
  }

  @Provide()
  update () {
    this.renderer.clear()
    if (this.debug) {
      this.renderer.render(this.scene, this.devCamera)
    } else if (this.composer.passes.length < 1) {
      this.renderer.render(this.scene, this.camera)
    } else {
      this.composer.render()
    }
  }

  @Provide()
  animate (time: number) {
    this.pointerOffset.x = this.pointer.x * .0012
    this.pointerOffset.y = this.pointer.y * .0032

    this.cameraLerpPosition.x = this.cameraOffset.x + this.pointerOffset.x
    this.cameraLerpPosition.y = this.cameraOffset.y + this.pointerOffset.y

    this.cameraLerpPosition.x += Math.cos(time * .6) * .0003
    this.cameraLerpPosition.y += Math.cos(time * .8) * .0002

    this.camera.position.x = MathUtils.lerp(this.camera.position.x, this.cameraLerpPosition.x, .1)
    this.camera.position.y = MathUtils.lerp(this.camera.position.y, this.cameraLerpPosition.y, .08)
    
  }

  @Provide()
  dispatch (time: number, delta: number) {
    this.$bus.$emit(Events.GL.RENDER, { time, delta })
  }

  @Provide()
  pointerUpdate ({ spreaded }: any) {
    this.pointer.copy(spreaded)

    /* if (Math.abs(delta.x) || Math.abs(delta.y)) {
      this.composer.$refs.fluid &&
      this.composer.$refs.fluid.splats.push({
        x: normalized.x, 
        y: normalized.y, 
        dx: delta.x * 10.0, 
        dy: delta.y * -10.0,
      })
    } */
  }

  syncDolly (offset: number) {
    this.cameraOffset.set(this.mapToWorld(offset), this.cameraOffset.y)
    this.snapTarget = null
  }
  
  snapDolly (offset: number) {
    this.snapTarget = this.mapToView(offset)
  }

  mapToWorld (value: number) {
    const { height } = this.getViewportFromFov(this.bounding.depth)
    const width = this.bounding.scene.x / height * this.viewport.y
    const offset = (this.bounding.scene.x - this.bounding.screen.x) / 2
    return MathUtils.mapLinear(value, 0, width - this.viewport.x, -offset, offset)
  }

  mapToView (value: number) {
    const { height } = this.getViewportFromFov(this.bounding.depth)
    const width = this.bounding.scene.x / height * this.viewport.y
    const offset = (this.bounding.scene.x - this.bounding.screen.x) / 2
    const limit = width - this.viewport.x
    const map = MathUtils.mapLinear(value, -offset, offset, 0, limit)
    return map < 0 ? 0 : map > limit ? limit : map
  }

  @Provide()
  getCameraWorldPosition (depth: number) {
    this.camera.getWorldDirection(this.cameraWorldOffset)
    
    this.cameraWorldOffset.multiplyScalar(depth)
    this.cameraWorldOffset.add(this.camera.position)
    
    return this.cameraWorldOffset
  }

  @Provide()
  getViewportFromFov (depth: number) {
    const vFov = this.camera.fov * Math.PI / 180
    const distance = this.cameraDefaultDepth - depth
    const height = 2 * Math.tan(vFov / 2) * Math.abs(distance)
    const width = height * this.camera.aspect
    return { width, height }
  }

  @Provide()
  boundingUpdate ({ size, depth }: any) {
    this.bounding.size = size
    this.bounding.depth = depth

    const { width: x, height: y } = this.getViewportFromFov(depth)
    
    this.bounding.scene.setX(x < size ? size : x).setY(y)
    this.bounding.screen.set(x, y)
  }

  @Provide()
  resize ({ width, height }: Vector2) {
    const pixelRatio = isNaN(this.dpr) ? this.$gpu.getDPR() : Math.min(this.dpr, 2)
    
    this.pixelRatio = Math.min(window.devicePixelRatio, pixelRatio)

    this.viewport.set(width, height)

    this.camera.aspect = width / height
    this.camera.updateProjectionMatrix()

    this.devCamera.aspect = width / height
    this.devCamera.updateProjectionMatrix()

    this.renderer.setSize(width, height)
    this.renderer.setPixelRatio(this.pixelRatio)

    this.composer.setSize(width, height)
    this.composer.setPixelRatio(this.pixelRatio)

    const { size, depth } = this.bounding

    this.boundingUpdate({ size, depth })

    this.$bus.$emit(Events.GL.RESIZE)
  }

  created () {
    this.renderer.autoClear = false
    this.renderer.outputEncoding = sRGBEncoding
    this.renderer.setClearColor(0xFDCDCD, 1)

    this.camera.position.set(0, 0, this.cameraDefaultDepth)
    this.camera.add(this.audioListener)

    this.devCamera.position.set(.01, .01, .01)
    this.devCamera.lookAt(0, 0, 0)

    this.orbit.enabled = false
  }

  render () {
    return (
      !this.$scopedSlots.$hasNormal &&
      this.$scopedSlots.default &&
      this.$scopedSlots.default({
        syncDolly: this.syncDolly,
        snapDolly: this.snapDolly,
        pixelRatio: this.pixelRatio,
        snapTarget: this.snapTarget,
        bounding: this.bounding,
        camera: this.camera,
        scene: this.scene,
      })
    )
  }
}