import { OrthographicCamera, BufferAttribute, Mesh, Material, BufferGeometry, Camera, WebGLRenderer } from 'three'
import CustomBufferGeometry from '@/webgl/geometries/CustomBufferGeometry'

class TriangleGeometry extends CustomBufferGeometry {
  constructor () {
    super()

    this.setAttribute('position', new BufferAttribute(new Float32Array([-1, -1, 3, -1, -1, 3]), 2))
    this.setAttribute('uv', new BufferAttribute(new Float32Array([0, 0, 2, 0, 0, 2]), 2))
  }
}

class FullScreenTriangle {
  scene: Mesh
  camera: Camera
  material: Material
  geometry: BufferGeometry

  constructor (material: Material) {
    
    this.material = material

    this.geometry = new TriangleGeometry()
    
    this.scene = new Mesh(this.geometry, this.material)
    
    this.camera = new OrthographicCamera(-1, 1, 1, -1, 0, 1)
  }

  render (renderer: WebGLRenderer) {
    renderer.render(this.scene, this.camera)
  }
}

export { FullScreenTriangle, TriangleGeometry }