import { RawShaderMaterial, WebGLRenderer, WebGLRenderTarget } from 'three'
import { FullScreenTriangle } from '@/webgl/postprocessing/FullScreenTriangle'
import { Pass } from 'three/examples/jsm/postprocessing/Pass'

class ChunkPass extends Pass {
  screen: FullScreenTriangle

  constructor (material: RawShaderMaterial) {
    super()

    this.screen = new FullScreenTriangle(material)
  }

  get uniforms () {
    const material = this.screen.material as RawShaderMaterial
    return material.uniforms
  }

  render (renderer: WebGLRenderer, writeBuffer: WebGLRenderTarget, readBuffer: WebGLRenderTarget) {
    this.uniforms.tDiffuse.value = readBuffer.texture

    if (this.renderToScreen) {
			renderer.setRenderTarget(null)
			this.screen.render(renderer)
		} else {
			renderer.setRenderTarget(writeBuffer)
			if (this.clear) renderer.clear()
			this.screen.render(renderer)
		}
  }
}

export { ChunkPass }